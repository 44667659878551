import axios from "axios";

export default class BaseModel {
  #fileFieldTypes = ["file", "pdf", "image", "attachment", "attachmentExcel"];
  fields = {};
  endpoint = null;
  getFields() {
    return Object.keys(this.fields).map;
  }
  addTemporaryField(key, field) {
    this.fields[key] = field;
  }
  exceptFields(fields, array) {
    array.forEach((key) => {
      delete fields[key];
    });
    return fields;
  }
  getEndpoint() {
    return this.endpoint;
  }
  hasFileField() {
    const filtered = Object.keys(this.fields).filter((key) => {
      return this.#fileFieldTypes.includes(this.fields[key].type);
    });
    return !!filtered.length;
  }
  init(defaultValues = {}) {
    let data = {};
    Object.keys(this.fields).forEach((key) => {
      data[key] = {
        value:
          typeof defaultValues.value !== "undefined"
            ? defaultValues.value
            : null,
        ...this.fields[key],
      };
      // data.push({
      //   value: typeof defaultValues.value !== 'undefined' ? defaultValues.value : null,
      //   ...this.fields[key]
      // })
    });
    return data;
  }
  initForEndpoint(value) {
    let formData;
    if (this.hasFileField()) {
      formData = new FormData();
      Object.keys(this.fields).map((key) => {
        if (typeof value[key] !== "undefined" && value[key] !== null) {
          if (typeof value[key] === "boolean") {
            formData.append(key, value[key] ? 1 : 0);
          } else if (typeof value[key] === "number") {
            formData.append(key, value[key]);
          } else if (
            typeof value[key] === "object" &&
            Object.keys(value[key]).length
          ) {
            formData.append(key, value[key]);
          } else if (value[key].trim() !== "") {
            formData.append(key, value[key]);
          }
        }
      });
      if (typeof value.ke_step_selanjutnya !== "undefined") {
        formData.append("ke_step_selanjutnya", value.ke_step_selanjutnya);
      }
    } else {
      formData = {};
      Object.keys(this.fields).map((key) => {
        if (typeof value[key] !== "undefined" && value[key] !== null) {
          if (typeof value[key] === "boolean") {
            formData[key] = value[key] ? 1 : 0;
          } else if (typeof value[key] === "number") {
            formData[key] = value[key];
          } else if (
            typeof value[key] === "object" &&
            Object.keys(value[key]).length
          ) {
            formData[key] = value[key];
          } else if (value[key].trim() !== "") {
            formData[key] = value[key];
          }
        }
      });
      formData["ke_step_selanjutnya"] = value.ke_step_selanjutnya;
    }
    return formData;
  }
  list(params = {}) {
    return new Promise((resolve, reject) => {
      const url = new URL(this.endpoint);
      const urlParams = new URLSearchParams(url.search);
      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] === "string" ||
          typeof params[key] === "number"
        ) {
          urlParams.append(key, params[key]);
        }
      });
      axios
        .get(this.endpoint + "?" + urlParams.toString())
        .then((response) => {
          resolve(
            typeof params.only_count !== "undefined"
              ? response.data
              : {
                  data: response.data.data,
                  current_page: response.data.data,
                  total: response.data.total,
                }
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  create(value) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.endpoint, this.initForEndpoint(value))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  find(id, params = {}) {
    return new Promise((resolve, reject) => {
      const url = new URL(this.endpoint);
      const urlParams = new URLSearchParams(url.search);
      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] === "string" ||
          typeof params[key] === "number"
        ) {
          urlParams.append(key, params[key]);
        }
      });
      axios
        .get(
          this.endpoint +
            "/" +
            id +
            (Object.keys(params).length ? "?" + urlParams.toString() : "")
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  update(id, value) {
    let formData = this.initForEndpoint(value);
    if (this.hasFileField()) {
      formData.append("_method", "PATCH");
    } else {
      formData["_method"] = "PATCH";
    }
    return new Promise((resolve, reject) => {
      axios
        .post(this.endpoint + "/" + id, formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  delete(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(this.endpoint + "/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
